import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemIcon from '@material-ui/core/ListItemIcon';


import {
   Box,
   Badge,
   Menu,
   Button,
   List,
   ListItem,
   Divider
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import { useAuth } from "../../utils/authContext";
import { useHistory } from "react-router-dom";

import { Initial } from 'react-initial'
import { Avatar } from '@material-ui/core';


const StyledBadge = withStyles({
   badge: {
      backgroundColor: 'var(--success)',
      color: 'var(--success)',
      boxShadow: '0 0 0 2px #fff',
      '&::after': {
         position: 'absolute',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         borderRadius: '50%',
         animation: '$ripple 1.2s infinite ease-in-out',
         border: '1px solid currentColor',
         content: '""'
      }
   },
   '@keyframes ripple': {
      '0%': {
         transform: 'scale(.8)',
         opacity: 1
      },
      '100%': {
         transform: 'scale(2.4)',
         opacity: 0
      }
   }
})(Badge);
export default function HeaderUserbox() {
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [authObject, dispatch] = useAuth();
   const history = useHistory();

   const handleClick = event => {
      setAnchorEl(event.currentTarget);
   };
   // if (authObject){

   // }
   // const us = new User();
   // us.get().then(data => console.log(data)).catch(error => console.log(error));


   const logOut = () => {
      dispatch({
         type: 'logout'
      });
      history.push('/login');
   }
   // console.log(authObject);
   const handleClose = () => {
      setAnchorEl(null);
   };




   return (
      !authObject ?
         <Redirect to="/login" />
         :
         <Fragment>
            <Button
               color="inherit"
               onClick={handleClick}
               className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
               <Box>
                  <StyledBadge
                     overlap="circle"
                     anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                     }}
                     variant="dot">
                     <Avatar style={{ height: '2em', width: '2em' }}>
                        <Initial
                           name={authObject.fullName}
                           fontSize={20}
                           useWords={1}
                        />

                     </Avatar>
                  </StyledBadge>
               </Box>
        
               <span className="pl-1 pl-xl-3">
                  <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
               </span>
            </Button>

            <Menu
               anchorEl={anchorEl}
               keepMounted
               getContentAnchorEl={null}
               open={Boolean(anchorEl)}
               anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center'
               }}
               transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
               }}
               onClose={handleClose}
            >
               <div className="dropdown-menu-lg overflow-hidden p-0">
                  <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
                     <Box>
                        <StyledBadge
                           overlap="circle"
                           anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                           }}
                           variant="dot">

                           <Avatar >
                              <Initial
                                 name={authObject.fullName}
                                 fontSize={20}
                                 useWords={1}
                              />

                           </Avatar>


                        </StyledBadge>
                     </Box>
                     <div className="p-1">
                        <div className="font-weight-bold text-center pt-2 line-height-1">
                           {authObject.fullName}
                        </div>
                     </div>
                     <Divider className="w-100 mt-2" />

                     <ListItem button onClick={logOut}>
                        <ListItemIcon><ExitToAppIcon /></ListItemIcon> Salir de Cuenta</ListItem>
                  </List>
               </div>
            </Menu>
         </Fragment>
   );
}
