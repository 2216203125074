import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../utils/authContext';

const PrivateRouter = ({ component: Component, ...rest }) => {
    // eslint-disable-next-line
    const [ authObject, dispatch] = useAuth();
    
    return (<Route {...rest} render={
        (props) => 
            authObject && authObject.auth ?
                <Component {...props} />
            : <Redirect to="/login" />
      
    }
    />);
};

export default PrivateRouter;
