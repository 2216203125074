import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { Grid } from '@material-ui/core';

import Console from '../../services/models/console';
import Common from '../../utils/common'
const clickHandler = (saleChannel, saleChannelLabel, is_pos, id_customers) => {
    Common.setStorage('order.id_sales_origin', saleChannel);
    Common.setStorage('order.sale_channel_label', saleChannelLabel);
    if(is_pos === 1){
        Common.setStorage('order.is_pos', 1);
        Common.setStorage('order.id_customers', id_customers);
        window.location.replace('/console/pos')
    }else{
        window.location.replace('/console/steps')

    }
}
const con = new Console();
export default function Cards({is_transition}) {
    let [saleChannel, setSaleChannel] = React.useState([]);
    let history = useHistory();
    React.useEffect(() => {
        con.getSalesChannel()
        .then(response =>{
            if(response.data.length === 1 || is_transition){
                if (is_transition) {
                    Common.clearOrderInfo();
                    Common.clearReturnInfo();
                    
                    Common.setStorage('order.is_trans_orders', 1);
                } else {
                    Common.setStorage('order.is_trans_orders', 0);
                }
                Common.setStorage('order.id_sales_origin', response.data[0].id_salesorigins);
                Common.setStorage('order.sale_channel_label', response.data[0].channel);
                if (response.data[0].is_pos === 1) {
                    Common.setStorage('order.is_pos', 1);
                    Common.setStorage('order.id_customers', response.data[0].id_customers);
                    window.location.replace('/console/pos');
                } else {
                    history.push('/console/steps');
                }

            }
            setSaleChannel(response.data)
        })
        .catch(e => console.log(e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <Grid container spacing={2}>
                {saleChannel.map(list => (
                    <Grid item xs={12} sm={4} lg={3} key={list.id_salesorigins}>
                        <Link to='' onClick={() => clickHandler(list.id_salesorigins, list.channel, list.is_pos, list.id_customers)} className="card card-box card-box-hover-rise card-box-hover text-black align-box-row align-items-start mb-4 p-4">
                        <div>
                            <div className="font-weight-bold font-size-sm">{list.channel}</div>
                        </div>
                        <div className="ml-auto card-hover-indicator align-self-center">
                            <FontAwesomeIcon
                                icon={['fas', 'chevron-right']}
                                className="font-size-xl"
                            />
                        </div>

                        </Link>
                    </Grid>
                ))}
            </Grid>
        </Fragment>
    );
}