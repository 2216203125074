import React, { createContext, useContext, useReducer } from 'react';
import Common from "./common";
export const CartContext = createContext();

const initialState = {
    'products': [],
    'services': [],
    'total': 0,
    'subtotal': 0,
    'shipping': 0,
    'tax': 0,
    'discount': 0,
    'coupons': [],
    'paymentType': '',
    'numPayments': 0,
    'payments': []
}

const updateCart = (cartObject) => {
    let total = 0;
    let subtotal = 0;
    let tax = 0;
    let discount = 0;
    cartObject.products.forEach(element => {
        total += element.price.fprice;
        subtotal += parseFloat((element.price.fprice / (1 + element.price.tax_pct / 100)).toFixed(2));
    });



    //Proceso de sumas y restas de servicios
    cartObject.services.forEach(element => {
        total += element.service.SPrice;
        subtotal += (element.service.SPrice / (1 + element.service.Tax / 100));
    });
    tax = total - subtotal;

    cartObject.coupons.forEach(element => {
        if (subtotal >= element.MinAmount) {
            if (element.Subcode === 'ALL') {
                if (element.DiscPerc) {

                    let discount_per = element.DiscPerc;
                    //Va a volver a calcular el carrito
                    tax = 0;
                    total = 0;
                    subtotal = 0;
                    discount = 0;
                    //Se agrega procedimiento de cauculo de TAXES en base a cada producto
                    cartObject.products.forEach(element => {
                        let tax_percent = parseFloat(element.price.tax_pct) / 100;

                        total += element.price.fprice;
                        let sub_total_now = ((parseFloat((element.price.fprice / (1 + element.price.tax_pct / 100)).toFixed(2))));
                        let discount_now = (parseFloat((discount_per * sub_total_now) / 100));

                        let subtotalpre = parseFloat(sub_total_now - discount_now);
                        discount += discount_now;
                        subtotal += sub_total_now;

                        if (tax_percent !== 0) {
                            tax += (subtotalpre) * tax_percent;
                        }


                    });

                } else if (element.DiscValue) {
                  
                    if (element.Applied === 'Gross') {
                        discount = element.DiscValue;
                        tax = parseFloat(((subtotal - discount) * 0.16).toFixed(2))
                    } else {
                        //Esto aplica a cada cupon
                        discount = (element.SubTotals - element.Discount) + element.Taxs;
                     
                    }
                }
            } else {
                // TODO CUPON POR PRODUCTO
            }
        }
        // Applied
        // DiscPerc
        // DiscValue
        // MinAmount
        // SubCode
        // LargeCode
    });

    total = subtotal - discount + tax;
    cartObject.discount = discount.toFixed(2);
    if (total < 0) {
        cartObject.discount = subtotal
    }
    cartObject.total = total.toFixed(2) === '-0.00' || total.toFixed(2) < 0 ? '0.00' : total.toFixed(2);
    cartObject.subtotal = subtotal.toFixed(2) === '-0.00' || subtotal.toFixed(2) < 0 ? '0.00' : subtotal.toFixed(2);
    cartObject.tax = tax.toFixed(2) === '-0.00' || tax.toFixed(2) < 0 ? '0.00' : tax.toFixed(2)
    console.log("CART OBJECT: ");
    console.log(cartObject);
    return cartObject;
}

const shopCartReducer = (state, action) => {
    let newState = {};
    let index;

    switch (action.type) {
        case "ADD_COUPON":
            // Only 1 Cuopon
            if (state.coupons.length === 1) {
                console.log('Only 1 Coupon by Order');
                return state;
            }

            newState = {
                ...state,
                coupons: [...state.coupons, action.data]
            };

            newState = updateCart(newState);
            Common.setStorage('order.cart', newState);
            return newState;
        case "DEL_COUPON":

            index = action.data.index;


            state.coupons.splice(index, 1);

            newState = {
                ...state,
            }

            newState = updateCart(newState)
            Common.setStorage('order.cart', newState)
            return newState
        case "ADD_PRODUCT":
            newState = {
                ...state,
                products: [...state.products, action.data]
            };
            // if(state.paymentType === ''){
            //newState['paymentType'] = action.data.price.pay_type.toLowerCase();
            newState['numPayments'] = action.data.price.flexi_pmts;
            // } else if (state.paymentType !== action.data.price.pay_type.toLowerCase() || state.numPayments !== action.data.price.flexi_pmts){
            //     console.log('PRECIO INVALIDO', `${state.paymentType} != ${action.data.price.pay_type}`)
            //     console.log('NUM PAGOS INVALIDO', `${state.numPayments} != ${action.data.price.flexi_pmts}`)
            //     return state;
            // }
            newState = updateCart(newState)
            Common.setStorage('order.cart', newState)
            return newState;
        case "DEL_PRODUCT":
            index = action.data.index;
            // let auxProducts = 
            state.products.splice(index, 1);
            // auxProducts.splice(index, 1)

            // if (state.products.length === 0){
            newState = { ...state };
            // }else{
            //     newState = {
            //         ...state
            //     }
            // }
            newState = updateCart(newState)
            Common.setStorage('order.cart', newState)
            return newState;
        case "ADD_SERVICE":
            newState = {
                ...state,
                services: [...state.services, action.data]
            };

            //newState['paymentType'] = "Referenced Deposit".toLowerCase();
            newState['numPayments'] = 1;

            newState = updateCart(newState)
            Common.setStorage('order.cart', newState)
            return newState;
        case "DEL_SERVICE":
            index = action.data.index;

            state.services.splice(index, 1);

            // if (state.services.length === 0){
            newState = { ...state };
            // }else{
            //     newState = {
            //         ...state
            //     }
            // }
            newState = updateCart(newState)
            Common.setStorage('order.cart', newState)
            return newState;
        case 'ADD_PAYMENT':
            console.log('added Payment', action.data);
            newState = {
                ...state,
                payments: [...state.payments, action.data]
            };

            //Obtener tipo de pago a travez de la suma de pagos
            newState['paymentType'] = updatePaymentCart(newState['payments']);

            Common.setStorage('order.cart', newState)
            return newState;
        case 'DEL_PAYMENT':
            index = action.data.index;
            state.payments.splice(index, 1);
            newState = { ...state }
            Common.setStorage('order.cart', newState);
            Common.setStorage('order.cart', initialState)

            //Obtener tipo de pago a travez de la suma de pagos
            newState['paymentType'] = updatePaymentCart(newState['payments']);
            return newState;
        case 'CLEAR_CART':
            Common.setStorage('order.cart', initialState)
            return initialState;

        default:
            console.log('Accion no existe')
            return state;
        // throw new Error();
    }
};

const updatePaymentCart = (paymentsData) => {
    let sumCC = 0, sumCash = 0, paymentType = '';


    paymentsData.forEach(element => {
        if (element.PaymentType === 'CC') {
            sumCC += parseFloat(element.Amount);
        } else if (element.PaymentType === 'Cash') {
            sumCash += parseFloat(element.Amount);
        }
    });


    if (sumCC > sumCash) {
        paymentType = 'credit-card'
    } else {
        paymentType = 'cod'
    }

    return paymentType;
}

export const CartProvider = props => {

    const [state, dispatch] = useReducer(shopCartReducer, Common.getStorage('order.cart', initialState));
    return (
        <CartContext.Provider value={[state, dispatch]}>
            {props.children}
        </CartContext.Provider>
    );
}

export const useCart = () => useContext(CartContext);
