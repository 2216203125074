import React, { Fragment } from 'react';

import clsx from 'clsx';

import { Paper } from '@material-ui/core';

import { connect } from 'react-redux';

const Footer = props => {
    const { sidebarToggle, footerFixed } = props;
    return (
        <Fragment>
            <Paper
                square

                className={clsx('app-footer', {
                    'app-footer--fixed': footerFixed,
                    'app-footer--fixed__collapsed': sidebarToggle
                })}>
                <div className="app-footer--inner">
                    <div className="app-footer--second">
                        <span>Direksys - Sales</span> &copy; {new Date().getFullYear()}
                    </div>
                </div>
            </Paper>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    footerFixed: state.ThemeOptions.footerFixed,
    sidebarToggle: state.ThemeOptions.sidebarToggle
});
export default connect(mapStateToProps)(Footer);
