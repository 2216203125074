import Console from "../../services/models/console";
import Common from "../../utils/common";
const cons = new Console();

const getItemsMenu = async () => {
  try {
    const currMenu = Common.getStorage('gui.menu', 0);
    if(currMenu !== 0){
      return currMenu;
    }
    let response = await cons.getMenuFilter({'id_parent' : 0});
    // console.log(response);
    let aux = [];
    for (let i = 0; i < response.data.length; i++) {
      const element = response.data[i];
      // console.log(element);
      let el = {
        label: element.label,
        icon: element.icon,
      };
      let childItems = await cons.getMenuFilter({'id_parent': element.id_menus});
      let arrayChild = [];
      // console.log(childItems);
      for (let j = 0; j < childItems.data.length; j++) {
        const childElement = childItems.data[j];
        if (childElement.link.includes("http")){
          arrayChild.push({
            "label": childElement.label,
            "description": childElement.label,
            "to": childElement.link,
            "target" :"_blank"
          });
        }else{
          arrayChild.push({
            "label": childElement.label,
            "description": childElement.label,
            "to": childElement.link
          });

        }
      }

      el['content'] = arrayChild;
      aux.push(el);
    }
    Common.setStorage('gui.menu', aux);
    return aux;
  } catch (error) {
    console.log('Error al obtener los elementos de Menu')
    console.log(error);
    return {};
  }
}
// items = cons.getMenu().then(menuItems => console.log(menuItems));

export default getItemsMenu;

