import React, { Fragment } from 'react';

import { connect } from 'react-redux';

const SidebarUserbox = props => {


  return (
    <Fragment>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(SidebarUserbox);
