import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ShoppingCart from '../../page/widgets/shoppingcart';
import {
  IconButton,
  Box,
  Typography,
  Popover,
  Tooltip,
 } from '@material-ui/core';



import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { useCart } from "../../utils/cartContext";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function HeaderDots() {

  // eslint-disable-next-line
  const [cartObject, cartAction] = useCart();
  const [cartItems, setCartItems] = React.useState(cartObject.products.length);
  
  // eslint-disable-next-line
  const [anchorEl3, setAnchorEl3] = React.useState(null);


  const handleClick3 = event => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  React.useEffect(() => {
    // console.log('cartItems', cartItems)
    setCartItems(cartObject.products.length)
  }, [cartObject])

  
  const open3 = Boolean(anchorEl3);
  
  

  return (
    <Fragment>
      <div className="d-flex align-items-center popover-header-wrapper">
      
          <Box component="span" pr="2">

            <Tooltip arrow title="Carrito">
            <IconButton
            onClick={handleClick3}
            color="inherit"
            className="btn-inverse mx-1 d-50">
              <div className="badge badge-pill badge-warning badge-header">
                  {cartItems}
              </div>
              <ShoppingCartIcon />
            </IconButton>
            </Tooltip>
            <Popover
            open={open3}
            anchorEl={anchorEl3}
            onClose={handleClose3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            classes={{
              paper: 'app-header-dots'
            }}>
            <div className="w-100">
              <ShoppingCart />

            </div>

            
          </Popover>
          </Box>
     
      </div>
    </Fragment>
  );
}
