// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react';
class Common {
    static decodeJwt = (encodeJwt) => {
        try {
            return JSON.parse(atob(encodeJwt.split('.')[1]));
        } catch (e) {
            console.log('Invalid JWT Token');
            return undefined;
        }
    }

    static setStorage = (key, value) => {
        if (key && value && value !== '') {
            localStorage.setItem(key, JSON.stringify(value));
        } else if (key && (!value | value === '')) {
            localStorage.removeItem(key);
        }
    }
    static getStorage = (key, value = '') => {
        if (key && localStorage.getItem(key)) {
            return JSON.parse(localStorage.getItem(key));
        }
        return value;
    }

    /**
     * Prefijo "pst"(persist) para no ser eliminado al cerrar sesión
     * Ejemplo: pst.clave: "clave" no será eliminado del LocalStorage
     */
    static clearStorage = () => {
        const keys = Object.keys(localStorage);
        // console.log(keys);
        keys.forEach(key => {
            if (key.substring(0,4) !== 'pst.') {
                localStorage.removeItem(key);
            }
        });
    }

    static clearOrderInfo = () => {
        for (var key in localStorage) {
            if (key.indexOf("order.") !== -1) {
                localStorage.removeItem(key);
            }
        }
    }
    static clearReturnInfo = () => {
        for (var key in localStorage) {
            if (key.indexOf("returns.") !== -1) {
                localStorage.removeItem(key);
            }
        }
    }

    static getOrderInfo = () => {
        let info = {};
        for (var key in localStorage) {
            if (key.indexOf("order.") !== -1) {
                info[key.replace('order.', '')] = this.getStorage(key)
            }
        }
        return info;
    }

    static getMoneyFormat = number => new Intl.NumberFormat("es-MX", { style: 'currency', currency: "MXN" }).format(number)
    
    static changeValue = (key, value, func) => {
        this.setStorage(key, value)
        if (typeof func === "function")
            func(value);
    }

    static useDebounce = (value, delay) => {
        const [debouncedValue, setDebouncedValue] = useState(value);
        useEffect(
            () => {
                const handler = setTimeout(() => {
                    setDebouncedValue(value);
                }, delay);
                return () => {
                    clearTimeout(handler);
                };
            },
            [value, delay]
        );
        return debouncedValue;
    }
    static usePrevious = value => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    static queryStringToJson = queryString => {
        let match;
        const pl = /\+/g;
        const search = /([^&=]+)=?([^&]*)/g;
        const decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); };
        const query = queryString;

        let urlParams = {};
        match = search.exec(query);
        while (match)
            urlParams[decode(match[1])] = decode(match[2]);

        return urlParams;
    }
    static getNestedObject = (nestedObj, pathArr) => {
        return pathArr.reduce((obj, key) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    }

    static isBarcode = barcode => {
        if (barcode.length < 8 || barcode.length > 18 ||
            (barcode.length !== 8 && barcode.length !== 12 &&
                barcode.length !== 13 && barcode.length !== 14 &&
                barcode.length !== 18)) {
            return false;
        }
        return true;

        

    }

}

export default Common;