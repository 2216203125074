// eslint-disable-next-line
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import { PacmanLoader } from 'react-spinners';

import MuiTheme from './theme';

import PrivateRouter from './utils/PrivateRouter'
// Layout Blueprints

import {
  LeftSidebar,
  // CollapsedSidebar,
  MinimalLayout,
  // PresentationLayout
} from './layout-blueprints';


import PagesLogin from './example-pages/PagesLogin';
import PagesError404 from './example-pages/PagesError404';
//  Sales Routes Components
// -------------------------------------------------------------------------------------
//  */

// const salesDashboard = lazy(() => import('./page/dashboard'));
import DashboardSales from './page/dashboard';
import ConsoleSales from './page/console';
// import ConsoleSalesSteps from './page/console/steps';
const ConsoleSalesSteps = lazy(() => import('./page/console/steps'));
const Pos = lazy(() => import('./page/console/pos'));
const Orders = lazy( () => import('./page/orders/index'));
const Returns = lazy(() => import('./page/returns/index'));
const ReturnsList = lazy(() => import('./page/returns/list'));
const Journals = lazy( () => import('./page/orders/journals/index'));
// /**
//  * 
// -------------------------------------------------------------------------------------
//  */

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <PacmanLoader color={'#5383ff'} loading={true} />
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/main" />
            <PrivateRouter path={[
              '/main',
              '/console',
              '/console/step*',
              '/console/pos*',
              '/orders',
              '/journals',
              '/returns/*',
              '/new_returns',
              '/trans_orders'
            ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <PrivateRouter exact
                      path="/main"
                      component={DashboardSales}
                    />
                    <PrivateRouter exact
                      path="/console"
                      component={ConsoleSales}
                    />
                    <PrivateRouter
                      path="/console/step*"
                      component={ConsoleSalesSteps}
                    />
                    <PrivateRouter
                      path="/console/pos*"
                      component={Pos}
                    />
                    <PrivateRouter
                      exact
                      path="/orders"
                      component={Orders}
                    />
                    <PrivateRouter 
                      exact
                      path="/journals"
                      component={Journals}
                    />
                    <PrivateRouter
                      path="/new_returns"
                      exact
                      component={Orders}
                    />
                    <PrivateRouter
                      path="/returns"
                      component={ReturnsList} exact/>
                    <PrivateRouter
                      path="/returns/:id"
                      component={Returns} />
                    <PrivateRouter exact
                      path="/trans_orders/:id"
                      component={ConsoleSales}
                    />

                  </motion.div>
                </Switch>
              </LeftSidebar>
            </PrivateRouter>
            
            <Route exact path="/login">
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/login" component={PagesLogin} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
            <Route component={PagesError404} /> 
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
