// import React, { createContext, useContext, useReducer } from 'react'; 

import { ajax } from 'rxjs/ajax';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import axios from 'axios';
import Common from './common';
import sha1 from 'crypto-js/sha1';

const LOGIN_PATH = process.env.REACT_APP_LOGIN_PATH;
const REFRESH_PATH = process.env.REACT_APP_REFRESH_PATH;
const API_BASE = process.env.REACT_APP_API_BASE;
// const

class Api{
    data;
    token;
    refresh;
    originalRequest;
    constructor(){
        this.data = Common.getStorage('auth');
        this.token = Common.getStorage('token');
        this.refresh = Common.getStorage('refresh');
        
        axios.interceptors.request.use(
            config => {
                const token = this.token;
                // console.log('Config Request', config);
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                    config.headers['Content-Type'] = 'application/json';
                }
                if( !config._retry){
                    config._retry = 1;
                }
                // config.headers['Content-Type'] = 'application/json';
                return config;
            },
            error => {
                Promise.reject(error)
            }
        );
        axios.interceptors.response.use(
        (response) => {
            return response
        }, (error) => {
            if (error.response.status === 401) {
                
                if ( error.config.url === REFRESH_PATH ) {
                    Common.clearStorage();
                    window.location.replace('/login');
                    return;
                }
                this.originalRequest = error.config;
                this.originalRequest._retry = 0;
                return axios({
                    url: REFRESH_PATH,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: { "refresh": this.refresh}
                }).then( res => {
                    // console.log(res);
                    if (res.status === 200) {
                        Common.setStorage('token', res.data.access);
                        Common.setStorage('refresh', res.data.refresh);
                        this.token = Common.getStorage('token');
                        this.refresh = Common.getStorage('refresh');
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
                        return axios(this.originalRequest);
                    }
                })
            }
        })
        
    }





    login = (user, pass) => {
        if(user !== 'admin'){
            pass = sha1(pass).toString();
        }
        return ajax({
            url: LOGIN_PATH,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                "username": user
                , "password": pass
            }
        }).pipe(
            tap( response => console.log('Response from Server', response) ),
            catchError(error => {
                console.log('error: ', error);
                return of(error);
            })
        );
        
    }

    call = (params) => {
        if(!params)
            return Promise.reject(new Error('need Params'));
        if(!params.endpoint)
            return Promise.reject(new Error('need Endpoint'));
        if(!params.method)
            params.method = 'GET';
        if(params.filters)
            params.filters = new URLSearchParams(params.filters).toString();

        // console.log(params);
        if(this.token){
            let url = API_BASE + params.endpoint;
            url += params.filters ? '?' + params.filters : '';
            return axios({
                url: url,
                method: params.method,
                data: { ...params.payload }
            })
        }
        return Promise.reject(new Error('need Login'));
   
    }


}

export default Api;
