import { Chip } from '@material-ui/core';
import ContactlessIcon from '@material-ui/icons/Contactless';
// import ErrorIcon from '@material-ui/icons/Error';
import React, { Fragment } from 'react';

import Common from "../../utils/common";
import Console from '../../services/models/console';


const HeaderMenu = () => {

    const [consoleName, setConsoleName] = React.useState({ value: null, payload: false });
    React.useEffect(() => {
        const con = new Console();
        con.getSalesChannel()
        
        .then(response => {
            if (response.data.length === 1) {
                Common.setStorage('order.id_sales_origin', response.data[0].id_salesorigins);
                Common.setStorage('order.sale_channel_label', response.data[0].channel);  
            }
            
            setConsoleName({ value: Common.getStorage('order.sale_channel_label'), payload: true });

            
        })
        .catch(e => console.log(e));
        
    }, []);
    
    // console.log("Valores al logueo", Common.getStorage('order.sale_channel_label'), Common.getStorage('order.id_sales_origin'));
    return (
        <Fragment>
            <div className="app-header-menu">

                {consoleName.payload ?
                    <ConsoleNameChip consoleName={consoleName.value} />
                    :
                    <Chip
                        icon={<ContactlessIcon style={{ color: '#0F4236' }} />}
                        label={`Cargando...`}

                        style={{ backgroundColor: '#39F7CA', color: '#0F4236' }}
                    />
                }


            </div>
        </Fragment>
    );
};

const ConsoleNameChip = ({ consoleName }) => {
    return (
        <>
            {
                consoleName ?
                    (<Chip
                        icon={<ContactlessIcon style={{ color: '#292B0B' }} />}
                        label={`Consola: ${consoleName} (${Common.getStorage('order.id_sales_origin')})`}              
                        style={{ backgroundColor: '#eeff41', color: '#292B0B' }}
                    />)
                    :
                    (
                    /* <Chip
                        icon={<ErrorIcon style={{ color: '#2E090B' }} />}
                        label={`No estas conectado a un canal de venta`}

                        style={{ backgroundColor: '#FF333D', color: '#2E090B' }}
                    /> */
                    <> </>
                    )

            }
        </>
    );
}

export default HeaderMenu;
