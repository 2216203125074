import React, { Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../utils/authContext";
import Api from "../../../utils/apiService";
import {
    Grid,
    Container,
    Input,
    InputLabel,
    InputAdornment,
    Card,
    CardContent,
    Button,
    FormControl,
    IconButton
} from '@material-ui/core';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import projectLogo from '../../../assets/logo_base.png';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// import Common from '../../../utils/common';
// import Console from '../../../services/models/console';
// ReactDOM.render(element, document.getElementById('root'));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const LoginView = (props) => {

    let history = useHistory();

    const [user, setUser] = React.useState('');
    const [pass, setPass] = React.useState('');

    const [authObject, dispatch] = useAuth();
    const [open, setOpen] = React.useState(false);
    const [openWarning, setOpenWarning] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleCloseWarning = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenWarning(false);
    }

    if (authObject && authObject.auth)
        // window.location.replace('/main');
        history.push('/main');

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            const api = new Api();
            if (user !== '' && pass !== '') {
                api.login(user, pass).subscribe((data) => {

                    if ('status' in data && data['status'] !== 200) {
                        setOpen(true);
                        return;
                    };
                    dispatch({
                        type: 'login',
                        payload: { token: data.response.access, refresh: data.response.refresh }
                    });
                    setOpenWarning(false);
                    setOpen(false);

                    window.location.replace('/main');
                });
            } else {
                setOpenWarning(true);
            }
        } catch (e) {
            console.log(e.message);
        }
    }


    return (
        <Fragment>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={open} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Usuario o contraseña no coinciden.
                </Alert>
            </Snackbar>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={openWarning} autoHideDuration={4000} onClose={handleCloseWarning}>
                <Alert onClose={handleCloseWarning} severity="warning">
                    Los campos de usuario y contraseña estan incompletos.
                </Alert>
            </Snackbar>

            <div className="app-wrapper min-vh-100">
                <div className="app-main flex-column">
                    <div className="app-content p-0">
                        <div className="app-content--inner d-flex align-items-center">
                            <div className="flex-grow-1 w-100 d-flex align-items-center">
                                <div className="bg-composed-wrapper--content py-5">
                                    <Container maxWidth="lg">
                                        <Grid container spacing={5} alignContent="center" alignItems="center">
                                            <Grid
                                                item
                                                xs={12}
                                                lg={6}
                                                className="d-flex flex-column align-items-center mx-auto">
                                                <span className="w-100 text-center text-md-center pb-4">
                                                    <IconButton
                                                        color="primary"
                                                        size="medium"
                                                        style={{ background: '#5c6bc0' }}>
                                                        <img
                                                            className="app-logo-img"
                                                            alt="Direksys Sales"
                                                            src={projectLogo}
                                                        />
                                                    </IconButton>

                                                </span>
                                                <Card className="m-0 w-100 p-3 border-0">

                                                    <CardContent className="p-3">

                                                        <p className="font-size-md text-xl-center text-center mb-0 text-black-50">
                                                            Inicia sesión para continuar
                                                        </p>

                                                        <form className="p-4" onSubmit={handleSubmit}>
                                                            <div className="mb-3">
                                                                <FormControl className="w-100 p-1">
                                                                    <InputLabel htmlFor="input-with-icon-adornment">
                                                                        Usuario
                                                                    </InputLabel>
                                                                    <Input
                                                                        fullWidth
                                                                        id="input-with-icon-adornment"
                                                                        value={user}
                                                                        onChange={e => setUser(e.target.value.trim())}
                                                                        startAdornment={
                                                                            <InputAdornment position="start">
                                                                                <MailOutlineTwoToneIcon style={{ color: '#637bfe' }} />
                                                                            </InputAdornment>
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                            <div className="mb-3">
                                                                <FormControl className="w-100 p-1">
                                                                    <InputLabel htmlFor="standard-adornment-password">
                                                                        Contraseña
                                                                    </InputLabel>
                                                                    <Input
                                                                        id="standard-adornment-password"
                                                                        fullWidth
                                                                        type="password"
                                                                        value={pass}
                                                                        onChange={e => setPass(e.target.value.trim())}
                                                                        startAdornment={
                                                                            <InputAdornment position="start">
                                                                                <LockOutlinedIcon style={{ color: '#637bfe' }} />
                                                                            </InputAdornment>
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </div>

                                                            <div className="text-center">
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    size="large"
                                                                    type="submit"
                                                                    endIcon={<VpnKeyOutlinedIcon />}
                                                                    className="my-2 mt-5">
                                                                    Iniciar Sesión
                                                                </Button>
                                                            </div>
                                                        </form>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export default LoginView;
