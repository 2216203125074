import Api from '../../utils/apiService';

class Console{
    api;
    constructor(){
        this.api = new Api();
    }
    getSalesChannel = filters => this.api.call({ endpoint: 'console/sales_channel/', filters: filters, method: 'GET' });
    getAllMenu = () => this.api.call({endpoint : 'console/menus/', method: 'GET'});
    getMenuFilter = filters => this.api.call({endpoint : 'console/menus/', filters: filters, method: 'GET'})
    getMenuElement = id => this.api.call({ endpoint : 'console/menus/' + id + '/', method: 'GET'});
    getStates = () => this.api.call({ endpoint: 'console/liststate/', method: 'GET' });
    getCity = filters => this.api.call({ endpoint: 'console/listcity', filters: filters, method: 'GET' });
    getZipByCounty = filters => this.api.call({ endpoint: 'console/zip', filters: filters, method: 'GET' });
    getCustomer = filters => this.api.call({ endpoint: 'console/listcustomer', filters: filters, method: 'GET' });
    getProducts = filters => this.api.call({ endpoint: 'console/listproducts', filters: filters, method: 'GET' });
    getProductsByUpc = filters => this.api.call({ endpoint: 'console/listproductsbyupc', filters: filters, method: 'GET' });
    createOrderPos = orderinfo => this.api.call({endpoint: 'console/createorder', payload: orderinfo, method: 'POST'});
    getOrders = filters => this.api.call({ endpoint: 'orders/', filters: filters, method: 'GET' })
    getReturns = filters => this.api.call({ endpoint: 'returns/', filters: filters, method: 'GET' })
    getTicket = filters => this.api.call({ endpoint: 'orders/getticket', filters: filters, method: 'GET' })
    getTicketReturns = filters => this.api.call({ endpoint: 'returns/getticket', filters: filters, method: 'GET' })
    
    getBanks = () => this.api.call({ endpoint: 'console/listbanks', method: 'GET' });
    
    getCouponInfo = filters => this.api.call({ endpoint: 'console/couponinfo', filters: filters, method: 'GET' })

    sendSMS = info => this.api.call({endpoint: 'notifications/sms/', payload: info, method: 'POST'});

    createReturnChargeBack = returnInfo => this.api.call({endpoint: 'returns/createreturns', payload: returnInfo, method: 'POST'});
    getReturnInfo = idReturn => this.api.call({ endpoint: 'returns/' + idReturn + '/', method: 'GET'});
    getOrderOrigin = filters => this.api.call({ endpoint: 'orders/getorigin', filters: filters, method: 'GET' })

    // Journals
    getJournals = filters => this.api.call({ endpoint: 'journals/', filters: filters, method: 'GET' })
    getPrevJournal = filters => this.api.call({ endpoint: 'journals/getprevjournal', filters: filters, method: 'GET' })
    saveJournal = (journalInfo) => this.api.call({ endpoint: 'journals/savejournal', payload: journalInfo, method: 'POST' })
    getTicketJournal = filters => this.api.call({ endpoint: 'journals/getjournalinfo', filters: filters, method: 'GET' })

    // List Services
    getServiceList = (filters) => this.api.call({ endpoint: 'services/listservices', filters: filters, method: 'GET' });

    //Others
    getUseCfdiList = () => this.api.call({ endpoint: 'others/list/use-cfdi', method: 'GET' });
    getRegimenFiscalList = () => this.api.call({ endpoint: 'others/list/regimen-fiscal', method: 'GET' });
}

export default Console;
