import React, { Fragment } from 'react';

import PagesLoginContent from '../../example-components/PagesLogin/PagesLoginContent';
export default function PagesLogin() {
  return (
    <Fragment>
      <PagesLoginContent />
    </Fragment>
  );
}
