import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, List, ListItem, Tooltip, IconButton } from '@material-ui/core';
import { CartContext } from "../../utils/cartContext";
import MuiAlert from '@material-ui/lab/Alert';
import Common from "../../utils/common";
import { Alert } from '@material-ui/lab';
import { CardContent } from '@material-ui/core';


import DeleteIcon from '@material-ui/icons/Delete';
import { ListItemSecondaryAction } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import { Typography } from '@material-ui/core';


const iconsPaymentsType = {
    'credit-card': { 'label': 'credit-card', 'color': 'text-success', 'color-alt': 'success' },
    'cod': { 'label': 'people-carry', 'color': 'text-primary', 'color-alt': 'info' },
    'referenced deposit': { 'label': 'university', 'color': 'text-warning', 'color-alt': 'warning' }
}



class ShoppingCart extends Component {


    render() {
        let [cartObject, cartDispatch] = this.context;
        // console.log('Reading from Shopping Cart' , cartObject)

        return (
            <Card className="card-box">
                <div className="bg-composed-wrapper" style={{ background: '#cf5050' }}>
                    <div className="bg-composed-wrapper--content text-light text-center p-4">
                        <h6 className="mb-1">
                            <FontAwesomeIcon icon={['fas', 'shopping-cart']} className="mr-1 fa-fw fa-lg" />
                            Carrito
                        </h6>
                    </div>
                </div>
                <div className="scroll-area-sm shadow-overflow" style={{ minHeight: "350px" }}>
                    <PerfectScrollbar>

                        <CardContent>

                            <List>
                                {
                                    (cartObject.products.length) > 0 ?
                                        <>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Productos:
                                            </Typography>
                                            {cartObject.products.map((ele, index) => (
                                                (
                                                    <ListItem key={index}>

                                                        <ListItemText
                                                            primary={ele.product.name}
                                                            secondary={Common.getMoneyFormat(ele.price.fprice)}
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <Tooltip arrow title="Borrar producto" placement='right'>
                                                                <IconButton edge="end" aria-label="delete" onClick={() => cartDispatch({ 'type': 'DEL_PRODUCT', 'data': { 'index': index } })}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ListItemSecondaryAction>

                                                    </ListItem>
                                                )
                                            ))}
                                        </>
                                        :
                                        (<Alert severity="warning" >No hay ningun producto en el carrito.</Alert>)
                                }
                            </List>

                            <List>
                                {
                                    (cartObject.services.length) > 0 ?
                                        <>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Servicios:
                                            </Typography>
                                            {cartObject.services.map((ele, index) => (
                                                (
                                                    <ListItem key={index}>

                                                        <ListItemText
                                                            primary={ele.service.Name}
                                                            secondary={Common.getMoneyFormat(ele.service.SPrice)}
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <Tooltip arrow title="Borrar servicio" placement='right'>
                                                                <IconButton edge="end" aria-label="delete" onClick={() => cartDispatch({ 'type': 'DEL_SERVICE', 'data': { 'index': index } })}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ListItemSecondaryAction>

                                                    </ListItem>
                                                )
                                            ))}
                                        </>
                                        :
                                        (<Alert severity="warning" >No hay ningun servicio en el carrito.</Alert>)
                                }
                            </List>

                            <List>

                                {cartObject.coupons.length > 0 ?
                                    (<>

                                        <Typography gutterBottom variant="h5" component="h2">
                                            Cupones Aplicados:
                                        </Typography>

                                        {cartObject.coupons.map((ele, index) => (


                                            <ListItem key={(index + 'cupon')}>

                                                <ListItemText
                                                    primary={ele.PublicID}
                                                    secondary={ele.Description}
                                                />
                                                <ListItemSecondaryAction>
                                                    <Tooltip arrow title="Borrar producto" placement='right'>
                                                        <IconButton edge="end" aria-label="delete" onClick={() => cartDispatch({ 'type': 'DEL_COUPON', 'data': { 'index': (index + 'cupon') } })}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>


                                            </ListItem>



                                        )
                                        )}

                                    </>


                                    )
                                    :

                                    (cartObject.products.length > 0 || cartObject.services.length > 0) && (<Alert severity="info" variant="outlined">No hay cupones seleccionados.</Alert>)

                                }
                            </List>

                        </CardContent>


                    </PerfectScrollbar>
                </div>
                <div className="card-footer d-flex justify-content-between">
                    {cartObject.products.length > 0 || cartObject.services.length > 0 ?
                        <table className="w-100">
                            <tbody>
                                <tr>
                                    <td>Subtotal</td>
                                    <td style={{ textAlign: 'right' }}>{Common.getMoneyFormat(cartObject.subtotal)}</td>
                                </tr>
                                <tr>
                                    <td>Descuentos</td>
                                    <td style={{ textAlign: 'right' }}>{Common.getMoneyFormat(cartObject.discount)}</td>
                                </tr>
                                <tr>
                                    <td>Impuestos</td>
                                    <td style={{ textAlign: 'right' }}>{Common.getMoneyFormat(cartObject.tax)}</td>
                                </tr>
                                <tr>
                                    <td>Manejo y Envio</td>
                                    <td style={{ textAlign: 'right' }}>{Common.getMoneyFormat(cartObject.shipping)}</td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <td style={{ textAlign: 'right' }}><strong>{Common.getMoneyFormat(cartObject.total)}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                        : ''}
                </div>
            
                    <React.Fragment>
                        <div style={{background: '#50659a'}}>
                            <div className="bg-composed-wrapper--content text-light text-center p-3">
                                <h6 className="mb-1">
                                    <FontAwesomeIcon icon={['fas', iconsPaymentsType['credit-card']['label']]} className="mr-1 fa-fw fa-lg" />
                                    Forma de pago
                                </h6>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-around">
                            <Tooltip arrow title="Tarjeta de credito">
                                <MuiAlert
                                    variant="outlined"
                                    className={cartObject.paymentType === 'credit-card' ? "alerts-alternate mb-2 paymethods" : "alerts-alternate mb-2 paymethods inactive"}
                                    color={iconsPaymentsType['credit-card']['color-alt']}>
                                    <span className={cartObject.paymentType === 'credit-card' ? iconsPaymentsType['credit-card']['color'] + " btn-wrapper--icon" : iconsPaymentsType['credit-card']['color'] + " btn-wrapper--icon payicon inactive"}>
                                        <FontAwesomeIcon icon={['fas', iconsPaymentsType['credit-card']['label']]} className="font-size-lg" />
                                        {cartObject.paymentType === 'credit-card' ?
                                            <div className="badge badge-pill badge-primary badge-header">
                                                {cartObject.numPayments}
                                            </div>
                                            : ''}
                                    </span>
                                </MuiAlert>
                            </Tooltip>
                            <Tooltip arrow title="Efectivo">
                                <MuiAlert
                                    variant="outlined"
                                    className={cartObject.paymentType === 'cod' ? "alerts-alternate mb-2 paymethods" : "alerts-alternate mb-2 paymethods inactive"}
                                    color={iconsPaymentsType['cod']['color-alt']}>
                                    <span className={cartObject.paymentType === 'cod' ? iconsPaymentsType['cod']['color'] + " btn-wrapper--icon" : iconsPaymentsType['cod']['color'] + " btn-wrapper--icon payicon inactive"}>
                                        <FontAwesomeIcon icon={['fas', iconsPaymentsType['cod']['label']]} className="font-size-lg" />
                                    </span>
                                </MuiAlert>
                            </Tooltip>
                        </div>

                    </React.Fragment>
                
            </Card>

        );
    }
}

ShoppingCart.contextType = CartContext;

export default ShoppingCart;