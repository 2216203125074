import React, { Fragment } from 'react';
// eslint-disable-next-line
import { ExampleWrapperSeamless } from '../../layout-components';
import SalesChannel from '../widgets/salesChannel';
import SalesChannelForce from '../widgets/salesChannelForce';
export default function ConsoleSales(props) {
    console.log('entra 1');
    return (
        <Fragment>
            <ExampleWrapperSeamless sectionHeading="Canal de Venta">
                <SalesChannel is_transition={props.match.params.id} />
            </ExampleWrapperSeamless>

        </Fragment>
    );
}


export const ConsoleSalesForced = ({ label = '' }) => {
    console.log('entra 2')
    return (
        <Fragment>
            <SalesChannelForce is_transition={null} label={label} />
        </Fragment>
    );
}
