import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { ListItem, Button, Collapse } from '@material-ui/core';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomRouterLink = forwardRef(function CustomLink(props, ref) {
  let aux = {...props}
  if( aux.location === ''){
    delete aux.location
  }
  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      {
        props.location ?
          <a href={props.to} target={props.target} className={props.className}>{props.children}</a>
          :
          <RouterLink {...aux} />
        
      }
    </div>
  );
});

const SidebarMenuListItem = props => {
  const {
    title,
    href,
    depth,
    children,
    icon,
    className,
    open: openProp,
    label: Label,
    ...rest
  } = props;

  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(open => !open);
  };


  if (children) {
    return (
      <ListItem
     
        {...rest}
        className={clsx('app-sidebar-item', className)}
        disableGutters>
        <Button
          color="primary"
        
          className={clsx('app-sidebar-button', { active: open })}
          onClick={handleToggle}

          startIcon={icon && <FontAwesomeIcon icon={["fas", icon]} />}
          >
   
          <span>{title}</span>
          {open ? (
            <ExpandLessIcon className="sidebar-expand-icon" color="inherit" />
          ) : (
            <ExpandLessIcon
              className="sidebar-expand-icon sidebar-expand-icon-rotate"
              color="inherit"
            />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem
        {...rest}
        className={clsx('app-sidebar-item', className)}
        disableGutters>
        <Button
          activeClassName="active-item"
          color="primary"
          disableRipple
          variant="text"
          className={clsx('app-sidebar-button-wrapper', `depth-${depth}`)}
          component={CustomRouterLink}
          location={rest['target'] ? href : ''}

          target={rest['target'] ? rest['target'] : ''}
          startIcon={icon && <FontAwesomeIcon icon={["fas", icon]} />}
          to={href}>
         
          {title}
          {Label && (
            <span className="menu-item-label">
              <Label />
            </span>
          )}
        </Button>
      </ListItem>
    );
  }
};

SidebarMenuListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

SidebarMenuListItem.defaultProps = {
  depth: 0,
  open: false
};

export default SidebarMenuListItem;
