import React, { Fragment } from 'react';
import { PageTitle } from '../../layout-components';

export default function DashboardSales() {

    return (
        <Fragment>

            <PageTitle
                titleHeading="Direksys Sales"
                titleDescription="Modulo de ventas de Direksys."
            />


        </Fragment>
    );
}
